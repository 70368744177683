exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"globalBg": "#f7f8fa",
	"menuText": "#666",
	"menuActiveText": "#5357a9",
	"subMenuActiveText": "#5357a9",
	"menuBg": "#fff",
	"menuHover": "#eff1f9",
	"subMenuBg": "#fff",
	"subMenuHover": "#bac6d2",
	"sideBarWidth": "210px"
};