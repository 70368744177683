import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
import user from './modules/user';
import tagsView from './modules/TagsView';
import permission from './modules/permission';
import info from './modules/info';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          tagsView: val.tagsView,
        };
      },
    }),
  ],
  modules: {
    app,
    settings,
    user,
    tagsView,
    info,
    permission,
  },
  getters,
});

export default store;
